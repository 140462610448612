/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'; import Grid from '@material-ui/core/Grid';
import {
  Button,
  Card,
  CircularProgress,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import CustomCheckBox from 'commons/components/CustomCheckBox';
import CustomAutoComplete from 'commons/components/CustomAutoComplete';
import CustomInputFile from 'commons/components/CustomInputFile';
import CustomTitle from 'commons/components/CustomTitle';
import CustomInputNumber from 'commons/components/CustomInputNumber';
import CustomInputAdornment from 'commons/components/CustomInputAdornment';
import { Alert, ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import HelpGoodPhotos from 'commons/components/HelpGoodPhotos';
import ImagesSection from 'commons/components/ImagesSection';
import { PhotoCamera } from '@material-ui/icons';
import { PATHS } from 'utils/paths';

const CustomToggleButton = ({
  label, onChange, name, value, disabled, option1, option2,
}) => {
  const theme = useTheme();
  const handleChange = (event, newValue) => {
    if (newValue !== null) {
      onChange({ target: { name, value: newValue } });
    }
  };

  return (
    <Grid item xs={12} sm={10} align="center">
      <Typography variant="body1" gutterBottom>{label}</Typography>
      <ToggleButtonGroup
        value={value}
        exclusive
        onChange={handleChange}
        aria-label={label}
        disabled={disabled}
      >
        <ToggleButton
          value
          aria-label="yes"
          style={value === true ? {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
          } : {}}
        >
          {option1}
        </ToggleButton>
        <ToggleButton
          value={false}
          aria-label="no"
          style={value === false ? {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
          } : {}}
        >
          {option2}
        </ToggleButton>
      </ToggleButtonGroup>
    </Grid>
  );
};

const CustomToggleButton2 = ({
  label, onChange, name, value, disabled, options,
}) => {
  const theme = useTheme();
  const handleChange = (event, newValue) => {
    if (newValue !== null) {
      onChange({ target: { name, value: newValue } });
    }
  };

  return (
    <Grid item xs={12} sm={10} align="center">
      <Typography variant="body1" gutterBottom>{label}</Typography>
      <ToggleButtonGroup
        value={value}
        exclusive
        onChange={handleChange}
        aria-label={label}
      >
        {options.map((option) => (
          <ToggleButton
            value={option.value}
            aria-label="yes"
            style={value === option.value ? {
              backgroundColor: theme.palette.primary.main,
              color: 'white',
            } : {}}
          >
            {option.text}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Grid>
  );
};

const RegisterForm = ({
  onSubmit,
  inputs = [],
  controls,
  actions,
  onControlChange,
  errors,
  validateFields,
  validateAll,
  loading,
  openAlert,
  clearAlert = null,
  profileComplete,
  isBicycleSelected,
  isMobileScreen,
  validateFieldsWithoutSettingErrors,
}) => {
  const [currentStep, setCurrentStep] = useState(0);

  const steps = [
    {
      label: 'Step 1',
      component: (
        <Grid container spacing={3} justifyContent="center" alignItems="center">
          <Grid
            container
            spacing={3}
            justifyContent="center"
            alignItems="center"
            style={{ marginRight: '2.9rem', marginLeft: '2.9rem' }}
          >
            {!profileComplete && (
            <Alert severity="info">
              <Typography align="center" className="message">
                <>
                  Para registrar, recibir transferencias, comprar o vender Bikes, debes completar tu perfil
                  {' '}
                  <Link to={PATHS.PROFILE}>aquí.</Link>
                </>
              </Typography>
            </Alert>
            )}
            {inputs.slice(0, 10).map((i) => (i.type === 'blankSpace' && !isMobileScreen ? (
              <Grid item xs={6} />
            ) : i.type === 'text' && !i.status && i.visible ? (
              <Grid
                key={i.name}
                item
                xs={12}
                sm={i.last ? 12 : 6}
                md={i.md ? i.md : 6}
                align="center"
              >
                <TextField
                  disabled={!profileComplete}
                  name={i.name}
                  label={i.label}
                  variant="outlined"
                  fullWidth
                  value={
                          i.getValue && !controls.foreignCheckbox
                            ? i.getValue(controls[i.name])
                            : controls[i.name]
                        }
                  onChange={(e) => onControlChange(e, i.getValue, !controls.foreignCheckbox)}
                  error={!!errors[i.name]}
                  helperText={errors[i.name] && errors[i.name][0]}
                  InputProps={
                          i.inputProp && {
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }
                        }
                />
              </Grid>
            ) : i.type === 'adornment' && !i.status && i.visible ? (
              <Grid
                key={i.name}
                item
                xs={i?.xs ? i.xs : 12}
                sm={i.last ? 12 : 6}
                md={i?.md ? i.md : 6}
                align="center"
              >
                <CustomInputAdornment
                  disabled={!profileComplete}
                  name={i.name}
                  label={i.label}
                  value={
                          i.getValue && !controls.foreignCheckbox
                            ? i.getValue(controls[i.name])
                            : controls[i.name]
                        }
                  onChange={(e) => onControlChange(e, i.getValue, !controls.foreignCheckbox)}
                  error={!!errors[i.name]}
                  helperText={errors[i.name] && errors[i.name][0]}
                  openAlert={openAlert}
                  clearAlert={clearAlert}
                />
              </Grid>
            ) : i.type === 'number' && !i.status && i.visible ? (
              <Grid key={i.name} item xs={12} sm={6} md={i.md ? i.md : 6} align="center">
                <CustomInputNumber
                  disabled={!profileComplete}
                  label={i.label}
                  name={i.name}
                  onChange={onControlChange}
                  value={controls[i.name]}
                  error={!!errors[i.name]}
                  helperText={errors[i.name]}
                />
              </Grid>
            ) : i.type === 'select' && !i.status && i.visible ? (
              <Grid
                key={i.name}
                item
                xs={12}
                sm={i.sm ? i.sm : 6}
                md={i.md ? i.md : 6}
                align="center"
              >
                <CustomAutoComplete
                  info={i.info}
                  disabled={!profileComplete}
                  name={i.name}
                  label={i.label}
                  value={controls[i.name]}
                  onChange={onControlChange}
                  options={i.options}
                  error={!!errors[i.name]}
                  errors={errors[i.name]}
                />
              </Grid>
            ) : i.type === 'vehicleTypeSelect' && !i.status && i.visible ? (
              <Grid
                key={i.name}
                item
                xs={12}
                sm={i.sm ? i.sm : 6}
                md={i.md ? i.md : 6}
                align="center"
              >
                <CustomAutoComplete
                  info={i.info}
                  disabled={!profileComplete}
                  name={i.name}
                  label={i.label}
                  value={controls[i.name]}
                  onChange={onControlChange}
                  options={i.options}
                  error={!!errors[i.name]}
                  errors={errors[i.name]}
                />
              </Grid>
            ) : i.type === 'date' && !i.status && i.visible ? (
              <>
                <Grid key={i.name} item xs={12} sm={6} md={i.md ? i.md : 6} align="center">
                  <TextField
                    name={i.name}
                    label={i.label}
                    variant="outlined"
                    fullWidth
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    value={controls[i.name]}
                    onChange={onControlChange}
                    disabled={controls.reported || !profileComplete || i.disabled}
                    error={!!errors[i.name]}
                    helperText={errors[i.name] && errors[i.name][0]}
                  />
                </Grid>
                <Grid xs={12} sm={6} md={6} align="center" />
              </>
            ) : null))}
          </Grid>
          {Boolean(controls?.vehicleTypeId) && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} align="center">
                <HelpGoodPhotos />
              </Grid>
              <Grid item xs={12} align="center">
                <ImagesSection
                  controls={controls}
                  actions={actions}
                  disabled={controls.reported || !profileComplete}
                />
              </Grid>
            </Grid>
          </>
          )}

        </Grid>
      ),
    },
    {
      label: 'Step 2',
      component: (
        <Grid container spacing={3} justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={12} align="center">
            <Grid container spacing={2} justifyContent="center" alignItems="center">
              <Grid item xs={12} sm={12} align="center">
                {Boolean(controls?.vehicleTypeId) && (
                <CustomToggleButton
                  disabled={!profileComplete}
                  label="¿Tienes la factura o boleta original de la Bike?"
                  onChange={onControlChange}
                  name="invoiceCheckbox"
                  value={controls.invoiceCheckbox}
                  option1="SI"
                  option2="NO"

                />
                )}
              </Grid>
              {controls.invoiceCheckbox && (
              <>
                <CustomToggleButton2
                  label="¿A nombre de quién fue emitida la boleta o factura?"
                  onChange={onControlChange}
                  name="invoiceInMyName"
                  value={controls.invoiceInMyName}
                  options={[{ text: 'A MI NOMBRE', value: 1 }, { text: 'DUEÑO ANTERIOR', value: 2 }, { text: 'EMPRESA', value: 3 }]}
                />

                <CustomToggleButton
                  label="¿La factura o boleta fue emitida en el extranjero?"
                  onChange={onControlChange}
                  name="foreignCheckbox"
                  value={controls.foreignCheckbox}
                  option1="SI"
                  option2="NO"
                />
              </>
              )}
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    {
      label: 'Step 3',
      component: (
        <Grid container spacing={3} justifyContent="flex-start" alignItems="flex-start">
          <Grid
            item
            xs={8}

          >
            <Typography style={{ padding: 5 }}>
              Ingresa la razón social
            </Typography>
            <TextField
              disabled={!profileComplete}
              name={inputs.find((el) => el.name === 'companyName').name}
              label={inputs.find((el) => el.name === 'companyName').label}
              variant="outlined"
              fullWidth
              value={
                          inputs.find((el) => el.name === 'companyName').getValue && !controls.foreignCheckbox
                            ? inputs.find((el) => el.name === 'companyName').getValue(controls[inputs.find((el) => el.name === 'companyName').name])
                            : controls[inputs.find((el) => el.name === 'companyName').name]
                        }
              onChange={(e) => onControlChange(e, inputs.find((el) => el.name === 'companyName').getValue, !controls.foreignCheckbox)}
              error={!!errors[inputs.find((el) => el.name === 'companyName').name]}
              helperText={errors[inputs.find((el) => el.name === 'companyName').name] && errors[inputs.find((el) => el.name === 'companyName').name][0]}
              InputProps={
                inputs.find((el) => el.name === 'companyName').inputProp && {
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }
                        }
            />
          </Grid>
          <Grid
            item
            xs={8}

          >
            <Typography style={{ padding: 5 }}>
              Ingresa el RUT de la empresa
            </Typography>
            <TextField
              disabled={!profileComplete}
              name={inputs.find((el) => el.name === 'companyTaxNumber').name}
              label={inputs.find((el) => el.name === 'companyTaxNumber').label}
              variant="outlined"
              fullWidth
              value={
                          inputs.find((el) => el.name === 'companyTaxNumber').getValue && !controls.foreignCheckbox
                            ? inputs.find((el) => el.name === 'companyTaxNumber').getValue(controls[inputs.find((el) => el.name === 'companyTaxNumber').name])
                            : controls[inputs.find((el) => el.name === 'companyTaxNumber').name]
                        }
              onChange={(e) => onControlChange(e, inputs.find((el) => el.name === 'companyTaxNumber').getValue, !controls.foreignCheckbox)}
              error={!!errors[inputs.find((el) => el.name === 'companyTaxNumber').name]}
              helperText={errors[inputs.find((el) => el.name === 'companyTaxNumber').name] && errors[inputs.find((el) => el.name === 'companyTaxNumber').name][0]}
              InputProps={
                inputs.find((el) => el.name === 'companyTaxNumber').inputProp && {
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }
                        }
            />
          </Grid>
          <Grid
            item
            xs={8}

          >
            <Typography style={{ padding: 5 }}>
              Ingresa la dirección de la empresa
            </Typography>
            <TextField
              disabled={!profileComplete}
              name={inputs.find((el) => el.name === 'companyAddress').name}
              label={inputs.find((el) => el.name === 'companyAddress').label}
              variant="outlined"
              fullWidth
              value={
                          inputs.find((el) => el.name === 'companyAddress').getValue && !controls.foreignCheckbox
                            ? inputs.find((el) => el.name === 'companyAddress').getValue(controls[inputs.find((el) => el.name === 'companyAddress').name])
                            : controls[inputs.find((el) => el.name === 'companyAddress').name]
                        }
              onChange={(e) => onControlChange(e, inputs.find((el) => el.name === 'companyAddress').getValue, !controls.foreignCheckbox)}
              error={!!errors[inputs.find((el) => el.name === 'companyAddress').name]}
              helperText={errors[inputs.find((el) => el.name === 'companyAddress').name] && errors[inputs.find((el) => el.name === 'companyAddress').name][0]}
              InputProps={
                inputs.find((el) => el.name === 'companyAddress').inputProp && {
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }
                        }
            />
          </Grid>
        </Grid>
      ),
    },
    {
      label: 'Step 4',
      component: (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6">Ingresa el código de serie y su foto</Typography>
          </Grid>
          <Grid item xs={9}>
            {!(!isBicycleSelected && Boolean(controls.vehicleTypeId)) ? (
              <CustomInputAdornment
                disabled={!profileComplete}
                name={inputs.find((el) => el.name === 'serial').name}
                label={inputs.find((el) => el.name === 'serial').label}
                value={
                          inputs.find((el) => el.name === 'serial').getValue && !controls.foreignCheckbox
                            ? inputs.find((el) => el.name === 'serial').getValue(controls[inputs.find((el) => el.name === 'serial').name])
                            : controls[inputs.find((el) => el.name === 'serial').name]
                        }
                onChange={(e) => onControlChange(e, inputs.find((el) => el.name === 'serial').getValue, !controls.foreignCheckbox)}
                error={!!errors[inputs.find((el) => el.name === 'serial').name]}
                helperText={errors[inputs.find((el) => el.name === 'serial').name] && errors[inputs.find((el) => el.name === 'serial').name][0]}
                openAlert={openAlert}
                clearAlert={clearAlert}
              />
            ) : (
              <>
                <CustomInputAdornment
                  disabled={!profileComplete}
                  name={inputs.find((el) => el.name === 'chassisSerial').name}
                  label={inputs.find((el) => el.name === 'chassisSerial').label}
                  value={
                          inputs.find((el) => el.name === 'chassisSerial').getValue && !controls.foreignCheckbox
                            ? inputs.find((el) => el.name === 'chassisSerial').getValue(controls[inputs.find((el) => el.name === 'chassisSerial').name])
                            : controls[inputs.find((el) => el.name === 'chassisSerial').name]
                        }
                  onChange={(e) => onControlChange(e, inputs.find((el) => el.name === 'chassisSerial').getValue, !controls.foreignCheckbox)}
                  error={!!errors[inputs.find((el) => el.name === 'chassisSerial').name]}
                  helperText={errors[inputs.find((el) => el.name === 'chassisSerial').name] && errors[inputs.find((el) => el.name === 'chassisSerial').name][0]}
                  openAlert={openAlert}
                  clearAlert={clearAlert}
                />
                <CustomInputAdornment
                  disabled={!profileComplete}
                  name={inputs.find((el) => el.name === 'engineSerial').name}
                  label={inputs.find((el) => el.name === 'engineSerial').label}
                  value={
                          inputs.find((el) => el.name === 'engineSerial').getValue && !controls.foreignCheckbox
                            ? inputs.find((el) => el.name === 'engineSerial').getValue(controls[inputs.find((el) => el.name === 'engineSerial').name])
                            : controls[inputs.find((el) => el.name === 'engineSerial').name]
                        }
                  onChange={(e) => onControlChange(e, inputs.find((el) => el.name === 'engineSerial').getValue, !controls.foreignCheckbox)}
                  error={!!errors[inputs.find((el) => el.name === 'engineSerial').name]}
                  helperText={errors[inputs.find((el) => el.name === 'engineSerial').name] && errors[inputs.find((el) => el.name === 'engineSerial').name][0]}
                  openAlert={openAlert}
                  clearAlert={clearAlert}
                />

              </>
            )}
          </Grid>
          <Grid item xs={3} container alignItems="center" justifyContent="center">
            {/* <Button variant="contained" color="primary" startIcon={<PhotoCamera />}>
              +
            </Button> */}
            <CustomInputFile
              title={!isMobileScreen ? `Foto del ${
                isBicycleSelected
                  ? 'Código de serie'
                  : `Número de chasis ${controls.invoiceCheckbox ? '(OPCIONAL)' : ''}`
              }` : ''}
              id="serialFile"
              name="serialFile"
              onChange={onControlChange}
              documentType="documentSerialId"
              value={controls.serialFile}
              error={!!errors.serialFile}
              errors={errors.serialFile}
              disabled={controls.reported || !profileComplete}
            />
          </Grid>
          {controls.invoiceCheckbox && (
          <>
            <Grid item xs={12}>
              <Typography variant="h6">Ingresa la información de tu factura o boleta y su foto</Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                fullWidth
                name={inputs.find((el) => el.name === 'invoiceTaxNumber').name}
                label={inputs.find((el) => el.name === 'invoiceTaxNumber').label}
                value={
                          inputs.find((el) => el.name === 'invoiceTaxNumber').getValue && !controls.foreignCheckbox
                            ? inputs.find((el) => el.name === 'invoiceTaxNumber').getValue(controls[inputs.find((el) => el.name === 'invoiceTaxNumber').name])
                            : controls[inputs.find((el) => el.name === 'invoiceTaxNumber').name]
                        }
                onChange={(e) => onControlChange(e, inputs.find((el) => el.name === 'invoiceTaxNumber').getValue, !controls.foreignCheckbox)}
                error={!!errors[inputs.find((el) => el.name === 'invoiceTaxNumber').name]}
                helperText={errors[inputs.find((el) => el.name === 'invoiceTaxNumber').name] && errors[inputs.find((el) => el.name === 'invoiceTaxNumber').name][0]}
              />
            </Grid>
            <Grid item xs={3} container alignItems="center" justifyContent="center">
              {/* <Button variant="contained" color="primary" startIcon={<PhotoCamera />}>
              +
            </Button> */}
              <CustomInputFile
                title={!isMobileScreen ? 'Foto de la Factura o Boleta.' : ''}
                id="invoiceFile"
                name="invoiceFile"
                onChange={onControlChange}
                documentType="documentInvoiceId"
                value={controls.invoiceFile}
                error={!!errors.invoiceFile}
                errors={errors.invoiceFile}
                disabled={controls.reported || !profileComplete}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name={inputs.find((el) => el.name === 'purchasedAt').name}
                label={inputs.find((el) => el.name === 'purchasedAt').label}
                variant="outlined"
                fullWidth
                type="date"
                InputLabelProps={{ shrink: true }}
                value={controls[inputs.find((el) => el.name === 'purchasedAt').name]}
                onChange={onControlChange}
                disabled={controls.reported || !profileComplete || inputs.find((el) => el.name === 'purchasedAt').disabled}
                error={!!errors[inputs.find((el) => el.name === 'purchasedAt').name]}
                helperText={errors[inputs.find((el) => el.name === 'purchasedAt').name] && errors[inputs.find((el) => el.name === 'purchasedAt').name][0]}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomAutoComplete
                info={inputs.find((el) => el.name === 'currencyId').info}
                disabled={!profileComplete}
                name={inputs.find((el) => el.name === 'currencyId').name}
                label={inputs.find((el) => el.name === 'currencyId').label}
                value={controls[inputs.find((el) => el.name === 'currencyId').name]}
                onChange={onControlChange}
                options={inputs.find((el) => el.name === 'currencyId').options}
                error={!!errors[inputs.find((el) => el.name === 'currencyId').name]}
                errors={errors[inputs.find((el) => el.name === 'currencyId').name]}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomInputNumber
                disabled={!profileComplete}
                label={inputs.find((el) => el.name === 'amount').label}
                name={inputs.find((el) => el.name === 'amount').name}
                onChange={onControlChange}
                value={controls[inputs.find((el) => el.name === 'amount').name]}
                error={!!errors[inputs.find((el) => el.name === 'amount').name]}
                helperText={errors[inputs.find((el) => el.name === 'amount').name]}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled={!profileComplete}
                name={inputs.find((el) => el.name === 'invoiceNumber').name}
                label={inputs.find((el) => el.name === 'invoiceNumber').label}
                variant="outlined"
                fullWidth
                value={
                          inputs.find((el) => el.name === 'invoiceNumber').getValue && !controls.foreignCheckbox
                            ? inputs.find((el) => el.name === 'invoiceNumber').getValue(controls[inputs.find((el) => el.name === 'invoiceNumber').name])
                            : controls[inputs.find((el) => el.name === 'invoiceNumber').name]
                        }
                onChange={(e) => onControlChange(e, inputs.find((el) => el.name === 'invoiceNumber').getValue, !controls.foreignCheckbox)}
                error={!!errors[inputs.find((el) => el.name === 'invoiceNumber').name]}
                helperText={errors[inputs.find((el) => el.name === 'invoiceNumber').name] && errors[inputs.find((el) => el.name === 'invoiceNumber').name][0]}
                InputProps={
                inputs.find((el) => el.name === 'invoiceNumber').inputProp && {
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }
                        }
              />

            </Grid>
          </>
          )}
          <Grid item xs={12} sm={12} align="center">
            <CustomCheckBox
              disabled={!profileComplete}
              label={`Declaro ser dueño legítimo y/o tener derecho para registrar esta ${
                isBicycleSelected ? 'bicicleta' : 'motocicleta'
              } y me comprometo a transferirla al momento de su venta.`}
              onChange={onControlChange}
              name="possessionCheckbox"
              value={controls.possessionCheckbox}
            />
          </Grid>
        </Grid>
      ),
    },
  ];

  const stepFields = [
    // Step 1 fields
    ['vehicleTypeId', 'color', 'version', 'year', 'brandId', 'modelId', 'sizeId', 'categoryId', 'rimId'],
    // Step 2 fields
    // ['invoiceCheckbox', 'foreignCheckbox'],
    [],
    // Step 3 fields
    ['companyName', 'companyTaxNumber', 'companyAddress'],
    // [],
    // Step 4 fields
    // [],
    ['amount', 'invoiceTaxNumber', 'invoiceNumber', 'invoiceFile', 'serialFile', 'possessionCheckbox', 'purchasedAt', 'currencyId', 'serial', 'chassisSerial', 'engineSerial'],
  ];

  const handleNext = () => {
    const currentFields = stepFields[currentStep];
    // const errors = validateFields(currentFields);
    const stepValid = validateFields(currentFields);
    if (stepValid) {
      // setCurrentStep((prevStep) => prevStep + 1);
      if (currentStep === 1 && (controls.invoiceInMyName !== 3 || !controls.invoiceCheckbox)) {
        console.log(2);
        setCurrentStep((prevStep) => prevStep + 2);
      } else {
        console.log(1);

        setCurrentStep((prevStep) => prevStep + 1);
      }
    } else {
      // setErrors(errors);
    }
  };
  // TODO: Caso de uso 2do step volver sin seleccionar checkbox
    // TODO: Mobile: Auto scroll changing steps
    // TODO: Mobile: quitar texto, reemplazar por iconImage

  const handleBack = () => {
    if (currentStep === 3 && (controls.invoiceInMyName !== 3 || !controls.invoiceCheckbox)) {
      setCurrentStep((prevStep) => prevStep - 2);
    } else {
      setCurrentStep((prevStep) => prevStep - 1);
    }
  };
  // const isDataCompleted = validateFields(stepFields[3]);
  // useEffect
  const isDataCompleted = false;
  return (
    <Card>
      <CardContent>
        <form onSubmit={onSubmit}>
          <input type="hidden" id="incomplete" name="incomplete" value="" />
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12}>
              {steps[currentStep].component}
            </Grid>
            <Grid item xs={12} align="center" style={{ marginTop: '30px' }}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item>
                  <Button
                    disabled={currentStep === 0}
                    onClick={handleBack}
                    variant="contained"
                    color="default"
                  >
                    Volver
                  </Button>
                </Grid>
                <Grid item>
                  {currentStep < steps.length - 1 ? (
                    <Button
                      id={`btn_continue_step_${currentStep + 1}`}
                      onClick={handleNext}
                      variant="contained"
                      color="primary"
                    >
                      Continuar
                    </Button>
                  ) : (
                    <Tooltip
                      title={
        !controls.possessionCheckbox ? 'Debes declarar ser el dueño legítimo, el resto lo puedes completar después  ' : ''
      }
                      placement="top"
                      arrow
                    >
                      <span>
                        {' '}
                        {/* This span is necessary to allow the Tooltip to wrap a disabled button */}
                        <Button
                          id="register_bike_button"
                          type="submit"
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            const a = validateFields(stepFields[3]);
                            console.log('a tiene ', a);
                            const event = {
                              target: {
                                name: 'dataCompleted', // Change this to the name of your form control
                                value: a, // Change this to the new value you want to set
                              },
                            };
                            onControlChange(event);
                          }}
                          disabled={loading || !controls.possessionCheckbox}
                        >
                          {loading ? (
                            <CircularProgress size={24} />
                          ) : !validateFieldsWithoutSettingErrors(stepFields[3]) ? (
                            'Guardar y continuar después'
                          ) : (
                            'Registrar'
                          )}
                        </Button>
                      </span>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>

      </CardContent>
    </Card>
  );
};

export default RegisterForm;
